/* eslint-disable */

import { createMemoryHistory, createWebHistory, createRouter } from 'vue-router'

import Home from './pages/Home.vue'
import Site from './pages/Index.vue'
import PageList from './pages/PageList.vue'
import PageDetail from './pages/PageDetail.vue'
import Canvas from './pages/Canvas.vue'
import CardEdit from './pages/cards/CardEdit.vue'
import CardMove from './pages/cards/CardMove.vue'
import CardShare from './pages/cards/CardShare.vue'
import CardChart from './pages/cards/CardChart.vue'
import MagicCreate from './pages/MagicCreate.vue'
import Stock from './pages/Stock.vue'
import Tag from './pages/Tag.vue'
import User from './pages/User.vue'
import UserList from './pages/UserList.vue'
import About from './pages/mine/About.vue'
import Vip from './pages/mine/Vip.vue'
import Setting from './pages/account/Setting.vue'
import Feedback from './pages/account/Feedback.vue'
import Agreement from './pages/account/Agreement.vue'
import Privacy from './pages/account/Privacy.vue'
import CollectData from './pages/account/CollectData.vue'
import ThirdShare from './pages/account/ThirdShare.vue'
import Modify from './pages/mine/Modify.vue'
import Mine from './pages/Mine.vue'
import Login from './pages/account/Login.vue'
import Signup from './pages/account/Signup.vue'
import { KeepAlive } from 'vue'

const routes = [
  {
    path: '/',
    component: Home,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/site',
    component: Site,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/explore',
    component: PageList,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/pages/:page_id',
    component: PageDetail,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/canvas',
    component: Canvas,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/cards/:card_id/edit',
    component: CardEdit,
    meta: { title: '股图图 - 首页' }
  },
  {
    path: '/cards/:card_id/share',
    component: CardShare,
    meta: { title: '股图图' }
  },
  {
    path: '/cards/:card_id/chart',
    component: CardChart,
    meta: { title: '股图图' }
  },
  {
    path: '/page/:page_id/move',
    component: CardMove,
    meta: { title: '移动卡片' }
  },
  {
    path: '/magic',
    component: MagicCreate,
    meta: { title: '股图图 - ai', keepAlive: true }
  },
  {
    path: '/stock/:symbol',
    component: Stock,
    meta: { title: '股图图 - 股票', index: 3 }
  },
  {
    path: '/tags/:tag',
    component: Tag,
    meta: { title: '股图图 - 股票', index: 3 }
  },
  {
    path: '/users/:user_id',
    component: User,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/users',
    component: UserList,
    meta: { title: '股图图 - 用户列表' }
  },
  {
    path: '/about',
    component: About,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/mine',
    component: Mine,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/profile/modify',
    component: Modify,
    meta: { title: '股图图' }
  },
  {
    path: '/setting',
    component: Setting,
    meta: { title: '设置', transition: 'slide-left' }
  },
  {
    path: '/feedback',
    component: Feedback,
    meta: { title: '问题反馈' }
  },
  {
    path: '/agreement',
    component: Agreement,
    meta: { title: '股图图 - 服务条款' }
  },
  {
    path: '/privacy',
    component: Privacy,
    meta: { title: '股图图 - 隐私协议' }
  },
  {
    path: '/collect_data',
    component: CollectData,
    meta: {
      title: '个人信息收集清单',
    },
  },
  {
    path: '/third_share',
    component: ThirdShare,
    meta: {
      title: '第三方共享信息',
    },
  },
  {
    path: '/vip',
    component: Vip,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/vip/:code',
    component: Vip,
    meta: { title: '股图图 | gututu.ai' }
  },
  {
    path: '/login',
    component: Login,
    meta: { title: '股图图 - 登录' }
  },
  {
    path: '/signup',
    component: Signup,
    meta: { title: '股图图 - 注册' }
  },
  {
    path: '/my_collections',
    component: PageList,
    meta: { title: '股图图 - 我的收藏', index: 1 }
  },
  {
    path: '/recent_view',
    component: PageList,
    meta: { title: '股图图 - 最近查看' }
  },
  {
    path: '/my_like',
    component: PageList,
    meta: { title: '股图图 - 我赞过的' }
  },
]

// history: createWebHistory(), // 使用 history 模式
const router = createRouter({
  history: createWebHistory(),
  // history: createMemoryHistory(),
  routes,
})

export default router