<!-- 标签榜单页 -->
<!-- 包含该标签全球股票Top20、中国榜单 -->
<template>

    <div class="h5-page">
      <!-- <t-navbar :title="stock.name" :fixed="true" left-arrow @left-click="goBack">
          <template #right>
              <t-icon name="ellipsis" size="24px" @click="showSheet = true"/>
          </template>
        </t-navbar> -->
  
        <G-Loading v-if="is_loading" />
  
        <div id="app">
  
          <div class="page-header">
            <img src="https://m.gututu.net/images/back2.png" style="position: relative; top: 10px; left: 10px;z-index: 2; width:30px;" @click="goBack(); "/>
  
            <div class="page-title">
              <div>{{stock.name}}</div>
            </div>
          </div>
  
          <div id="card-list" class="card-list ">
            <div style="width:100%; padding:15px; height:60px;" v-if="loadingAnalysis">
              <div style="float:left;margin-right:10px;">小图正在尝试分析</div> 
              <t-loading class="bottom-loading" theme="dots" size="20px" style="float:left;margin-top:3px;"/>
            </div>
  
            <div class="analysis" v-if="content != ''">
              <b>小图分析</b>：{{ content }}
            </div>
  
            <div class="chart">
              <Bar :data="change_data" :options="options" />
            </div>
  
            <div class="chart">
              <Bar :data="price_data" :options="options" /> 
            </div>
  
            <div class="chart">
              <Bar :data="revenue_data" :options="options" /> 
            </div>
  
            
          </div>
          <Disclaimer />
  
        </div>
        
  
  </div>
  </template>
  
  <script>
   /* eslint-disable */
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  import { getRandomColor } from '../common/tools'
  import api from '../common/api'; 
  import { useShareStore } from '@/stores/ShareStore';
  import Disclaimer from '@/components/Disclaimer.vue';
  
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
  
  export default {
   name: 'Stock',
   components: {
      Bar,
      Disclaimer
   },
   computed: {
    cap_format() {
      return (this.stock.market_cap / 100000000).toFixed(0);
    }
   },
   data() {
      return {
        shareStore: useShareStore(),
        is_loading: false,
        tag: '',
        stock: {},
        change: [],
        revenue: [],
        price: [],
        content: '',
        loadingAnalysis: false,
        change_data: {
          labels: '涨跌幅',
          datasets: []
        },
        price_data: {
          labels: '涨跌幅',
          datasets: []
        },
        revenue_data: {
                labels: '收入(亿)',
                datasets: []
        },
        options: {
          responsive: true,
          plugins: {
            datalabels: {
                  formatter: (value, ctx) => {
                      // let sum = 0;
                      // ctx.dataset.data.forEach((data) => {
                      //   sum += parseInt(data);
                      // });
                      // let percentage = Math.round((value / sum) * 100);
                      // let text = percentage + '%';
                      // if (percentage > 3)
                      //   return parseInt(value);
                      // else
                        return value
                  },
                  color: '#fff',
                  labels: {
                  title: {
                    font: {
                      size: 14,
                      weight: 'bold'
                    }
                  }
                }
              },
        }
        }
      };
    },
   created() {
    //   this.sharePageViewed()
      this.tag = this.$route.params.tag
      this.loadData()
   },
    methods: {
      goBack() {
        this.$router.back();
      },
      sharePageViewed() {
          // 标记分享页面已经浏览过
          const from = this.$route.query.from
          if (from == 'share') {
            this.shareStore.viewed()
          }
      },
      loadData() {
          this.is_loading = true
          this.loadModule1()
          this.loadModule2()
          this.loadModule3()
      },
      loadStockInfo() {
  
      },
      loadModule1() {
        // /tag/xx/global
        // /tag//xx/china
        
        api.get('/stock/' + this.symbol + '/basic').then(response => {
            if (response.data.status == 'success') {
              this.stock = response.data.data
  
              var data = [this.stock.twenty_days_change, 
                          this.stock.sixty_days_change,
                          this.stock.current_year_change]
  
              this.change_data = {
                labels: ['20日', '60日', '年初至今'],
                datasets: [{
                  label: '涨跌幅(%)',
                  data: data,
                  borderColor: `#36A808`, // 交替颜色
                  backgroundColor: getRandomColor()
                }]
              }
            }
          })
  
      },
      loadModule2() {
        // api.get('/stock/' + this.symbol + '/trend').then(response => {
        //     if (response.data.status == 'success') {
        //       this.change = response.data.data
        //     }
        //   })
  
          
          api.get('/stock/' + this.symbol + '/price').then(response => {
            if (response.data.status == 'success') {
              var data = response.data.data
  
              this.price_data = {
                labels: ['-5%以上', '-5%到-2%', '-2%到0', '0-2%', '2-5%', '5%以上'],
                datasets: [{
                  label: '涨跌天数分布',
                  data: data,
                  borderColor: `#36A808`, // 交替颜色
                  backgroundColor: getRandomColor()
                }]
              }
            }
          })
      },
      loadModule3() {
        api.get('/stock/' + this.symbol + '/revenue').then(response => {
            if (response.data.status == 'success') {
              var result = response.data.data
              
              this.revenue_data = {
                labels: result.map(item => item.report_period),
                datasets: [{
                  label: '收入(亿)',
                  data: result.map(item => item.revenue / 1e8),
                  borderColor: `#36A808`, // 交替颜色
                  backgroundColor: getRandomColor()
                }]
              }
  
              this.is_loading = false
            }
          })
      },
    },
  };
  </script>
  
  <style scoped>
    .analysis {
      margin-top: 15px;
      padding: 15px;
      font-size: 16px;
      border-radius: 10px;
      background: #e3f9e9;
    } 
  
    .chart {
      margin-top:15px;
    }
  
    .page-header {
      height: 160px;
      padding: 10px;
      background-color: #4158D0;
      background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    }
  
    .page-title {
      color: #FFF;
      font-weight: bold;
      font-size: 18px;
      margin-top:50px;
      margin-left:15px;
    }
  
    .card-list {
      padding: 0 15px;
      margin-bottom: 20px;
    }
  
  </style>